import { Link } from "react-router-dom";

const BlogPage = () => {
    const posts = [
        // {
        //     title: "First Post",
        //     link: "/blog/2022-12-26-first",
        // },
        // {
        //     title: null,
        //     link: null,
        // }
    ];

    return (
        <div className="page">
            <h1>Blog</h1>
            {posts.reverse().map((post, index) => (
                <div className="line-break">
                    <Link className="blog-link" to={post.link} target="_self">
                        {post.title}
                    </Link>
                </div>
            ))}
        </div>
    );
};

export {BlogPage};
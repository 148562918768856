import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './index.css';
import { Home } from './components/Home.js';
import { About } from './components/About.js';
import { ProjectPage } from './components/ProjectPage.js';
import { BlogPage } from './components/BlogPage.js';
import { Post } from './components/Post.js';
import { HeaderLinks } from './components/HeaderLinks.js';
import { Resume } from './components/Resume.js';

<link href='https://fonts.googleapis.com/css?family=Inter' rel='stylesheet'></link>

class App extends React.Component {
    render() {
        return (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route element={<HeaderLinks />}>
              <Route path="/about" element={<About />} />
              <Route path="/projects">
                  <Route index element={<ProjectPage />} />
                  <Route path=":id" element={<Post />} />
              </Route>
              <Route path="/blog">
                  <Route index element={<BlogPage />} />
                  <Route path=":id" element={<Post />} />
              </Route>
            </Route>
            <Route path="/resume" element={<Resume />} />
            <Route path="*" element={<h1>Not Found</h1>} />
          </Routes>
        );
    }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

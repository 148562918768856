import { Link } from "react-router-dom";

const Profile = () => (
    <div className="home-profile">
        <h1 className="home-profile-name">Carlos Hurtado</h1>
        <p id="home-profile-l1">
            I am a lifelong learner and enjoy trying out new things and exploring new technologies.
        </p>
        <p id="home-profile-l1">
            Interested in technology, health, nature, and music.
        </p>
        <p id="home-profile-l2">
            Data Science Master's student at EPFL.
        </p>
    </div>
)

const Group = (props) => {
    const isClickableTitle = (title) => {
        return title.toLowerCase() === "projects" || title.toLowerCase() === "blog";
    };

    return(
        <div className="row">
            <div className="column">
                {isClickableTitle(props.name) ? (
                    <Link to={`/${props.name.toLowerCase()}`} className="home-index-title">
                        <h2 className="home-index-title">{props.name.toUpperCase()}</h2>
                    </Link>
                ) : (
                    <h2 className="home-index-title">{props.name.toUpperCase()}</h2>
                )}
            </div>
            <div className="column">
                {props.pages.map((page) => (
                    <p className="index-links">
                        {page[2] === "_self" ? (
                            <Link
                                key={page[0]}
                                className="home-index-group-link"
                                to={page[1]}
                            >
                                {page[0]}
                            </Link>
                        ) : (
                            <a
                                key={page[0]}
                                className="home-index-group-link" 
                                href={page[1]} target={page[2]} rel={"noreferrer"}
                            >
                                {page[0]}
                            </a>
                        )
                        }
                    </p>
                ))}
            </div>
        </div>
    );
}

const Index = () => (
    <div className="home-index">
        <Group key={"self"} name={"carloshurtado.xyz"} pages={[
                                    ["Home","/", "_self"], 
                                    ["About","/about", "_self"], 
                                    ["Projects","/projects", "_self"], 
                                    ["Blog","/blog", "_self"]
                                    ]}/>
        <Group key={"projects"} name={"projects"} pages={[
                                    ["EMNLP 2024 Publication", "https://arxiv.org/abs/2407.13141", "_blank"],
                                    ["ICASSP 2023 Publication","https://arxiv.org/abs/2210.17475", "_blank"],
                                    ["Heart Failure Monitoring","https://github.com/CarlOwOs/VH_and_PE_codes", "_blank"], 
                                    ["Restaurant Recommender","https://github.com/megaelius/Delfos-datathon-fme", "_blank"],
                                    ]}/>
        <Group key={"web"} name={"web"} pages={[
                                    //["Twitter","https://twitter.com/CarlOwOs1", "_blank"], 
                                    ["Github","https://github.com/CarlOwOs", "_blank"], 
                                    ["LinkedIn","https://www.linkedin.com/in/carloshuco/", "_blank"],
                                    ["Resume","/resume", "_self"],
                                    ["Scholar","https://scholar.google.com/citations?user=RA-2E_kAAAAJ&hl=en", "_blank"],
                                    ]}/>
        <Group key={"blog"} name={"blog"} pages={[
                                    //["alog2log2log2log2log2log2log2","projec1", "_self"], 
                                    ]}/>
    </div>
)

const Home = () => (
    <div className="home-container">
        <Profile />
        <Index />
    </div>
);

export {Home};
import { Link } from "react-router-dom";

const ProjectHead = (props) => (
    <div className="project-head">
        <div className="project-head-top">
            {props.link2 && (
                <Link to={props.link2.url} className="project-link">
                    {props.link2.text}
                </Link>
            )}
            {props.link3 && (
                <Link to={props.link3.url} className="project-link">
                    {props.link3.text}
                </Link>
            )}
            {props.link1 ? (
                <Link className="project-head-link" to={props.link1} target="_self">
                    {props.title}
                </Link>
            ) : (
                <h2 className="project-head-text">{props.title}</h2>  
            )}
            {props.link2 && <p className="project-head-text">&nbsp;&nbsp;|&nbsp;&nbsp;</p>}
            <a className="project-head-link" href={props.link2} target={"_blank"} rel={"noreferrer"}> {props.link2_name} </a>
            <p className="project-head-text">&nbsp;&nbsp;</p>
            {props.link3 && (
                <a className="project-head-link" href={props.link3} target={"_blank"} rel={"noreferrer"}> {props.link3_name} </a>
            )}
        </div>
        <p>{props.description}</p>
    </div>
);

export {ProjectHead};
import Iframe from 'react-iframe'
import { HeaderLinks } from './HeaderLinks'

const Resume = () => (
    <>
        <HeaderLinks />
        <Iframe 
            className='resume-iframe' 
            src="https://drive.google.com/file/d/1nihYWUk4fjMhSMqpuLeZ5zY54LYUcr5n/preview"
        />
    </>
)

export { Resume };

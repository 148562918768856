import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

const Post = () => {
    const { id } = useParams();
    const [content, setContent] = useState(null);
    
    useEffect(() => {
        async function fetchData() {
            const file = await import(`../data/_posts/${id}.md`);
            const response = await fetch(file.default);
            const fileContents = await response.text();
            setContent(fileContents);
        }
        fetchData();
    }, [id]);

    return (
        <div className="page">
            <ReactMarkdown>{content}</ReactMarkdown>
        </div>
    )
};

export {Post};
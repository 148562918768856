import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from 'react';

const About = () => {
    const [content, setContent] = useState(null);
    
    useEffect(() => {
        async function fetchData() {
            const file = await import(`../data/_posts/about.md`);
            const response = await fetch(file.default);
            const fileContents = await response.text();
            setContent(fileContents);
        }
        fetchData();
    }, []);

    return (
        <div className="page">
            <ReactMarkdown>{content}</ReactMarkdown>
        </div>
    )
};

export {About};